<template>
  <div v-if="!isDisabled">
    <div
      v-if="form.style && form.style.id === 1"
      class="badge-container chip chip-1"
      :class="classes"
      :style="{
        'border-radius': borderRadius,
        color: !form.textColor.collapsed ? '#' + form.textColor[type].color : '#1B1823',
        'font-size': (!form.fontStyle.collapsed && form.fontStyle.textSize) ? form.fontStyle.textSize.title + 'px' : '14px',
        'font-weight': (!form.fontStyle.collapsed && form.fontStyle.weight) ? form.fontStyle.weight.value : 400,
        'font-family': (!form.fontStyle.collapsed && form.fontStyle.font) ? form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif',
        background: '#' + getStyle('frameBackgroundColor').color,
        border: '1px solid #' + getStyle('frameBorderColor').color
      }"
    >
      <div class="chip-symbol">
        <SelectedSymbol :form="form" :type="type"></SelectedSymbol>
      </div>
      <div class="badge-text chip-text">{{ getStyle('customText') }}</div>
    </div>
    <div v-if="form.style && form.style.id === 2" class="badge-container chip chip-2" :class="classes">
      <div
        class="chip-text-wrapper"
        :style="{
        color: !form.textColor.collapsed ? '#' + form.textColor[type].color : '#1B1823',
        'font-size': (!form.fontStyle.collapsed && form.fontStyle.textSize) ? form.fontStyle.textSize.title + 'px' : '14px',
        'font-weight': (!form.fontStyle.collapsed && form.fontStyle.weight) ? form.fontStyle.weight.value : 400,
        'font-family': (!form.fontStyle.collapsed && form.fontStyle.font) ? form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
      }"
      >
        <div class="badge-text chip-text">{{ getStyle('customText') }}</div>
      </div>
      <div
        class="badge-body chip-percent"
        :style="{ 'border-radius': borderRadius, background: '#' + getStyle('frameBackgroundColor').color }"
      >
        <SelectedSymbol :form="form" :type="type"></SelectedSymbol>
        <div
          :style="{
          color: !form.innerTextColor.collapsed ? '#' + form.innerTextColor[type + 'Inner'].color : '#ffffff',
          'font-size': (!form.fontStyle.collapsed && form.fontStyle.textSize) ? form.fontStyle.textSize.title + 'px' : '14px',
          'font-weight': (!form.fontStyle.collapsed && form.fontStyle.weight) ? form.fontStyle.weight.value : 400,
          'font-family': (!form.fontStyle.collapsed && form.fontStyle.font) ? form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
          }"
        >100%</div>
      </div>
    </div>
    <div
      v-if="form.style && form.style.id === 3"
      class="badge-container chip chip-3"
      :class="classes"
      :style="{
        color: !form.textColor.collapsed ? '#' + form.textColor[type].color : '#1B1823',
        'font-size': (!form.fontStyle.collapsed && form.fontStyle.textSize) ? form.fontStyle.textSize.title + 'px' : '14px',
        'font-weight': (!form.fontStyle.collapsed && form.fontStyle.weight) ? form.fontStyle.weight.value : 400,
        'font-family': (!form.fontStyle.collapsed && form.fontStyle.font) ? form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
      }"
    >
      <div class="badge-text chip-text">{{ getStyle('customText') }}</div>
      <div class="chip-symbol">
        <SelectedSymbol :form="form" :type="type"></SelectedSymbol>
      </div>
    </div>
  </div>
  <div v-else class="chip-disabled" :class="classes" />
</template>

<script>
import SelectedSymbol from './SelectedSymbol'

export default {
  name: 'SelectedBadge',
  components: { SelectedSymbol },
  props: {
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  },
  methods: {
    getStyle (key) {
      if (this.form[key] && this.form[key].default) {
        return !this.form[key].collapsed ? this.form[key][this.type] : this.form[key].default[this.type]
      } else {
        return this.form[key] ? this.form[key][this.type] : {}
      }
    }
  },
  computed: {
    borderRadius () {
      if (this.form.roundness) {
        if (this.form.roundness.id === 1) {
          return '0'
        } else if (this.form.roundness.id === 2) {
          return '6px'
        } else if (this.form.roundness.id === 3) {
          return '100px'
        }
      }
      return '0'
    },
    isDisabled () {
      return (!this.form.style || !this.form.symbol || !this.form.roundness) && !(this.form.style && this.form.style.id === 3 && this.form.symbol)
    }
  }
}
</script>

<style lang="scss" scoped>
.BadgePagePreview {
  .header {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .chip-disabled {
    background: rgba(27, 24, 35, 0.05);
    opacity: 0.25;
    border: 1px solid rgba(27, 24, 35, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    min-height: 44px;
    margin-bottom: 24px;
  }
  .result {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
    border-radius: 6px;
    padding: 24px;
    .title {
      font-weight: 600;
      font-size: 11px;
      letter-spacing: 0.05em;
      color: rgba(27, 24, 35, .5);
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .chip-1 {
      position: relative;
      background: #E9F7EF;
      border: 1px solid rgba(39, 174, 96, 0.2);
      box-sizing: border-box;
      padding: 7px 14px 7px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 44px;
      ::v-deep {
        .selected-symbol {
          display: flex;
          align-items: center;
          svg {
            position: absolute;
            left: 14px;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .chip-2 {
      display: flex;
      align-items: center;
      min-height: 44px;
      .chip-text-wrapper {
        margin-right: 6px;
      }
      .chip-percent {
        padding: 0 6px;
        color: white;
        background: #1B1823;
        border-radius: 4px;
        font-weight: 500;
        display: flex;
        align-items: center;
        min-height: 24px;
        ::v-deep {
          svg {
            margin-right: 6px;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    .chip-3 {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 44px;
      ::v-deep {
        .selected-symbol {
          display: flex;
          align-items: center;
          svg {
            margin-left: 6px;
            width: 12px;
            height: 12px;
            border-radius: 3px;
          }
        }
      }
    }
    .chip {
      margin-bottom: 24px;
      &-text {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &-wrapper {
          max-width: 80%;
        }
      }
    }
  }
}
</style>
