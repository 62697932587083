<template>
  <div class="selected-symbol" v-if="form.symbol">
    <template v-if="form.symbol.id === 1">
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.25168 1.14697C8.01369 -0.224651 9.9863 -0.224653 10.7483 1.14697L17.3493 13.0287C18.0899 14.3618 17.1259 16 15.601 16H2.39903C0.87406 16 -0.089876 14.3618 0.650714 13.0287L7.25168 1.14697Z" :fill="'#' + getStyle('symbolColor').color"/>
      </svg>
    </template>
    <template v-if="form.symbol.id === 2">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" :fill="'#' + getStyle('symbolColor').color"/>
      </svg>
    </template>
    <template v-if="form.symbol.id === 3">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="4" :fill="'#' + getStyle('symbolColor').color"/>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SelectedSymbol',
  props: {
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    getStyle (key) {
      if (this.form[key] && this.form[key].default) {
        return !this.form[key].collapsed ? this.form[key][this.type] : this.form[key].default[this.type]
      } else {
        return this.form[key] ? this.form[key][this.type] : {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-symbol {
  display: flex;
}
</style>
