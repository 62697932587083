<template>
  <div class="BadgePageStyleSelect">
    <div class="title">{{ title }}</div>
    <div class="items">
      <div
        v-for="item in items"
        :key="item.id"
        class="item"
        :class="{
          'item-selected': item.selected,
          //'item-show': isShowDefault && !items.find(style => style.selected),
          'item-disabled': isDisabledSelect
        }"
        @click="!isDisabledSelect && $emit('select', item.id)"
      >
        <div class="item-content" v-html="item.html"/>
        <div v-if="item.selected" class="selected">
          <icon-check-badge width="10" height="8" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BadgeStyleSelect',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    id: {
      type: Number,
      default: 0
    },
    isShowDefault: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabledSelect () {
      if (this.isDisabled) {
        return true
      } else {
        return (this.form.style && this.form.style.id === 3 && this.form.symbol && this.id === 3)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.BadgePageStyleSelect {
  margin-top: 24px;
  .title {
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.05em;
    color: rgba(27, 24, 35, .5);
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .items {
    display: flex;
    margin: 0 -10px;
    @media (max-width: 500px) {
      flex-direction: column;
      margin-top: 0;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 18px 14px;
      width: 33.33%;
      margin: 0 10px;
      background: #FFFFFF;
      box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
      border-radius: 6px;
      cursor: pointer;
      opacity: 0.5;
      @media (max-width: 500px) {
        margin: 10px 0;
        width: 100%;
      }
      &-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-disabled {
        cursor: default;
      }
      &-show {
        opacity: 1;
      }
      &-selected {
        opacity: 1;
      }
      .selected {
        position: absolute;
        right: -5px;
        bottom: -5px;
        background: #6551E0;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
