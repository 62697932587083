var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isDisabled)?_c('div',[(_vm.form.style && _vm.form.style.id === 1)?_c('div',{staticClass:"badge-container chip chip-1",class:_vm.classes,style:({
      'border-radius': _vm.borderRadius,
      color: !_vm.form.textColor.collapsed ? '#' + _vm.form.textColor[_vm.type].color : '#1B1823',
      'font-size': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.textSize) ? _vm.form.fontStyle.textSize.title + 'px' : '14px',
      'font-weight': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.weight) ? _vm.form.fontStyle.weight.value : 400,
      'font-family': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.font) ? _vm.form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif',
      background: '#' + _vm.getStyle('frameBackgroundColor').color,
      border: '1px solid #' + _vm.getStyle('frameBorderColor').color
    })},[_c('div',{staticClass:"chip-symbol"},[_c('SelectedSymbol',{attrs:{"form":_vm.form,"type":_vm.type}})],1),_c('div',{staticClass:"badge-text chip-text"},[_vm._v(_vm._s(_vm.getStyle('customText')))])]):_vm._e(),(_vm.form.style && _vm.form.style.id === 2)?_c('div',{staticClass:"badge-container chip chip-2",class:_vm.classes},[_c('div',{staticClass:"chip-text-wrapper",style:({
      color: !_vm.form.textColor.collapsed ? '#' + _vm.form.textColor[_vm.type].color : '#1B1823',
      'font-size': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.textSize) ? _vm.form.fontStyle.textSize.title + 'px' : '14px',
      'font-weight': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.weight) ? _vm.form.fontStyle.weight.value : 400,
      'font-family': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.font) ? _vm.form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
    })},[_c('div',{staticClass:"badge-text chip-text"},[_vm._v(_vm._s(_vm.getStyle('customText')))])]),_c('div',{staticClass:"badge-body chip-percent",style:({ 'border-radius': _vm.borderRadius, background: '#' + _vm.getStyle('frameBackgroundColor').color })},[_c('SelectedSymbol',{attrs:{"form":_vm.form,"type":_vm.type}}),_c('div',{style:({
        color: !_vm.form.innerTextColor.collapsed ? '#' + _vm.form.innerTextColor[_vm.type + 'Inner'].color : '#ffffff',
        'font-size': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.textSize) ? _vm.form.fontStyle.textSize.title + 'px' : '14px',
        'font-weight': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.weight) ? _vm.form.fontStyle.weight.value : 400,
        'font-family': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.font) ? _vm.form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
        })},[_vm._v("100%")])],1)]):_vm._e(),(_vm.form.style && _vm.form.style.id === 3)?_c('div',{staticClass:"badge-container chip chip-3",class:_vm.classes,style:({
      color: !_vm.form.textColor.collapsed ? '#' + _vm.form.textColor[_vm.type].color : '#1B1823',
      'font-size': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.textSize) ? _vm.form.fontStyle.textSize.title + 'px' : '14px',
      'font-weight': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.weight) ? _vm.form.fontStyle.weight.value : 400,
      'font-family': (!_vm.form.fontStyle.collapsed && _vm.form.fontStyle.font) ? _vm.form.fontStyle.font.value : '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
    })},[_c('div',{staticClass:"badge-text chip-text"},[_vm._v(_vm._s(_vm.getStyle('customText')))]),_c('div',{staticClass:"chip-symbol"},[_c('SelectedSymbol',{attrs:{"form":_vm.form,"type":_vm.type}})],1)]):_vm._e()]):_c('div',{staticClass:"chip-disabled",class:_vm.classes})}
var staticRenderFns = []

export { render, staticRenderFns }