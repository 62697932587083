export default [
  {
    fontStyle: {
      collapsed: false,
      font: {
        id: 1,
        title: 'Inter',
        value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
      },
      weight: {
        id: 4,
        title: 'Medium',
        value: 500
      },
      textSize: {
        id: 7,
        title: 14
      }
    },
    textColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: '1B1823',
        isShowPicker: false
      },
      unavailable: {
        color: '1B1823',
        isShowPicker: false
      },
      investigation: {
        color: '1B1823',
        isShowPicker: false
      }
    },
    innerTextColor: {
      collapsed: true,
      type: 'color',
      operationalInner: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      unavailableInner: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      investigationInner: {
        color: 'FFFFFF',
        isShowPicker: false
      }
    },
    symbolColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: '27AE60',
        isShowPicker: false
      },
      unavailable: {
        color: 'A3A7A5',
        isShowPicker: false
      },
      investigation: {
        color: 'F2994A',
        isShowPicker: false
      }
    },
    frameBackgroundColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: 'E9F7EF',
        isShowPicker: false
      },
      unavailable: {
        color: 'F6F6F6',
        isShowPicker: false
      },
      investigation: {
        color: 'FEF8EF',
        isShowPicker: false
      }
    },
    frameBorderColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: 'C2E8D2',
        isShowPicker: false
      },
      unavailable: {
        color: 'E5E6E6',
        isShowPicker: false
      },
      investigation: {
        color: 'FCE5CE',
        isShowPicker: false
      }
    },
    customText: {
      collapsed: false,
      operational: 'All Systems Operational',
      unavailable: 'Server Unavailable',
      investigation: 'Exploring Issues'
    },
    customCss: {
      collapsed: false,
      value: ''
    },
    badge_url: {
      collapsed: false,
      value: ''
    }
  },
  {
    fontStyle: {
      collapsed: false,
      font: {
        id: 1,
        title: 'Inter',
        value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
      },
      weight: {
        id: 4,
        title: 'Medium',
        value: 500
      },
      textSize: {
        id: 9,
        title: 16
      }
    },
    textColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: '1B1823',
        isShowPicker: false
      },
      unavailable: {
        color: '1B1823',
        isShowPicker: false
      },
      investigation: {
        color: '1B1823',
        isShowPicker: false
      }
    },
    innerTextColor: {
      collapsed: true,
      type: 'color',
      operationalInner: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      unavailableInner: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      investigationInner: {
        color: 'FFFFFF',
        isShowPicker: false
      }
    },
    symbolColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      unavailable: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      investigation: {
        color: 'FFFFFF',
        isShowPicker: false
      }
    },
    frameBackgroundColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: '27AE60',
        isShowPicker: false
      },
      unavailable: {
        color: '1B1823',
        isShowPicker: false
      },
      investigation: {
        color: 'F2994A',
        isShowPicker: false
      }
    },
    frameBorderColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: 'C2E8D2',
        isShowPicker: false
      },
      unavailable: {
        color: 'E5E6E6',
        isShowPicker: false
      },
      investigation: {
        color: 'FCE5CE',
        isShowPicker: false
      }
    },
    customText: {
      collapsed: false,
      operational: 'Status',
      unavailable: 'Status',
      investigation: 'Status'
    },
    customCss: {
      collapsed: false,
      value: ''
    },
    badge_url: {
      collapsed: false,
      value: ''
    }
  },
  {
    fontStyle: {
      collapsed: false,
      font: {
        id: 1,
        title: 'Inter',
        value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
      },
      weight: {
        id: 4,
        title: 'Medium',
        value: 500
      },
      textSize: {
        id: 9,
        title: 16
      }
    },
    textColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: '1B1823',
        isShowPicker: false
      },
      unavailable: {
        color: '1B1823',
        isShowPicker: false
      },
      investigation: {
        color: '1B1823',
        isShowPicker: false
      }
    },
    innerTextColor: {
      collapsed: true,
      type: 'color',
      operationalInner: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      unavailableInner: {
        color: 'FFFFFF',
        isShowPicker: false
      },
      investigationInner: {
        color: 'FFFFFF',
        isShowPicker: false
      }
    },
    symbolColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: '27AE60',
        isShowPicker: false
      },
      unavailable: {
        color: 'EB5757',
        isShowPicker: false
      },
      investigation: {
        color: 'F2994A',
        isShowPicker: false
      }
    },
    frameBackgroundColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: 'E9F7EF',
        isShowPicker: false
      },
      unavailable: {
        color: 'F6F6F6',
        isShowPicker: false
      },
      investigation: {
        color: 'FEF8EF',
        isShowPicker: false
      }
    },
    frameBorderColor: {
      collapsed: false,
      type: 'color',
      operational: {
        color: 'C2E8D2',
        isShowPicker: false
      },
      unavailable: {
        color: 'E5E6E6',
        isShowPicker: false
      },
      investigation: {
        color: 'FCE5CE',
        isShowPicker: false
      }
    },
    customText: {
      collapsed: false,
      operational: 'Status',
      unavailable: 'Status',
      investigation: 'Status'
    },
    customCss: {
      collapsed: false,
      value: ''
    },
    badge_url: {
      collapsed: false,
      value: ''
    }
  }
]
