<template>
  <div class="BadgePagePreview">
    <div class="title-wrapper">
      <div class="header page-header mb-0">Preview</div>
      <icon-close class="close-icon" @click="$emit('close')"/>
    </div>
    <div class="result">
      <div class="title">operational</div>
      <SelectedBadge :form="form" :type="'operational'"></SelectedBadge>
      <div class="title">unavailable</div>
      <SelectedBadge :form="form" :type="'unavailable'"></SelectedBadge>
      <div class="title">investigation</div>
      <SelectedBadge :form="form" :type="'investigation'" classes="mb-0"></SelectedBadge>
    </div>
    <template v-if="isSubscribed">
      <div v-if="badgeId" class="header page-header code-title">Code</div>
      <div v-show="badgeId" class="code">
        <div v-if="badgeId" class="copy-code">
          <codemirror
            ref="myCm"
            :options="cmOptions"
            :value='`
<div id="pulsetic-badge"></div>
<script src="https://cdn.pulsetic.com/index.js" async></script>
<script>
    ${code}
</script>
          `'
          ></codemirror>
        </div>
        <div class="d-flex">
          <div ref="copyToClipboard">
            <base-button class="copy" @click='copyToClipboard(`
      <div id="pulsetic-badge"></div>
    <script src="https://cdn.pulsetic.com/index.js" async></script>
    <script>
        ${code}
    </script>
      `)'>
              <icon-copy class="copy-icon"/>
              Copy to Clipboard
            </base-button>
          </div>
        </div>
      </div>
    </template>
    <div class="upgrade" v-else>
      <UpgradeBadge text="Status Badge embed code is available only for paid customers."/>
    </div>
  </div>
</template>

<script>
import SelectedBadge from './SelectedBadge'
import tippy from 'tippy.js'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-dark.css'
import UpgradeBadge from '../Common/UpgradeBadge'

export default {
  name: 'BadgePreview',
  components: { SelectedBadge, codemirror, UpgradeBadge },
  props: {
    result: {
      type: Object,
      default: () => {
        return {}
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    badgeId: {
      type: Number,
      default: () => {
        return ''
      }
    },
    siteId: {
      type: Number,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      copyToClipboardTippy: null,
      cmOptions: {
        tabSize: 4,
        mode: 'text/javascript',
        theme: 'ayu-dark',
        lineNumbers: false,
        line: true,
        scrollbarStyle: null
      },
      apiLink: process.env.VUE_APP_BASE_URL
    }
  },
  mounted () {
    this.copyToClipboardTippy = tippy(this.$refs.copyToClipboard, {
      content: 'Copied',
      arrow: false,
      placement: 'top',
      trigger: 'manual',
      animation: 'shift-away-extreme'
    })
    setTimeout(() => {
      let codeMirror = document.getElementsByClassName('CodeMirror')[0]
      if (codeMirror) {
        codeMirror.children[0].innerHTML = ''
        let child = codeMirror.parentElement.children[0]
        codeMirror.parentElement.removeChild(child)
      }
    }, 500)
  },
  computed: {
    badgeMode () {
      if (this.apiLink === 'https://api.pulsetic.com/') {
        return 'production'
      } else if (this.apiLink === 'https://dev.pulsetic.com/') {
        return 'dev'
      } else if (this.apiLink === 'http://localhost:3400/') {
        return 'local'
      }
      return 'dev'
    },

    code () {
      if (this.badgeMode !== 'production') {
        return `window.onload = function () {
        Pulsetic.init({
          monitor_id: ${this.siteId},
          element_id: "pulsetic-badge",
          mode: "${this.badgeMode}"
    });
    }`
      } else {
        return `window.onload = function () {
        Pulsetic.init({
          monitor_id: ${this.siteId},
          element_id: "pulsetic-badge"
    });
    }`
      }
    },

    isSubscribed () {
      return this.user.teamowner_plan && this.user.teamowner_plan.name !== 'Free'
    },

    ...mapState('authentication', [
      'user'
    ])
  },
  methods: {
    async copyToClipboard (code) {
      await this.$copyText(code)
      this.triggerTippy()
    },

    triggerTippy () {
      this.copyToClipboardTippy.show()

      setTimeout(() => {
        this.copyToClipboardTippy.hide()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.BadgePagePreview {
  .header {
    font-size: 18px;
    margin-bottom: 10px;
    height: auto;
  }
  .close-icon {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  .title {
    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
    }
  }
  .result {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
    border-radius: 6px;
    padding: 24px;
    .title {
      font-weight: 600;
      font-size: 11px;
      letter-spacing: 0.05em;
      color: rgba(27, 24, 35, .5);
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .badge {
      &-wrapper {
        margin-bottom: 24px;
      }
    }
  }
  .code {
    background: #0b0e13;
    box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
    border-radius: 6px;
    padding: 24px;
    &-title {
      margin-top: 48px;
      margin-bottom: 14px;
    }
    .copy {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 6px;
      color: #FFFFFF;
      &-code {
        padding-bottom: 24px;
        &::v-deep {
          .CodeMirror {
            height: 250px !important;
            position: relative;
            user-select: none;
            overflow: hidden;
            .CodeMirror-line:first-child, .CodeMirror-line:last-child {
              display: none;
            }
          }
        }
      }
      &-icon {
        margin-right: 10px;
      }
    }
  }
  .upgrade {
    margin-top: 32px;
  }
}
</style>
