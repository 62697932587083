<template>
  <ValidationObserver ref="observer">
    <form class="BadgePageForm">
      <div class="header page-header mb-0">Website</div>
      <div class="website-wrapper">
        <base-dropdown
          class="website-main"
          :class="{'website-with-icon': selectedSite && selectedSite.url}"
          item-label="title"
          :searchable="true"
          track-by="id"
          v-model="selectedSite"
          :options="sites"
        />
        <img v-if="selectedSite" :src="selectedSite.url | favicon" class="select-icon" />
      </div>
      <BadgeStyleSelect
        v-for="item in styleItemsList"
        :items="item.items"
        :id="item.id"
        :key="item.id"
        :form="form"
        :title="item.title"
        :isShowDefault="item.id === 1"
        @select="selectStyle(item.title, item.id, $event)"
        :isDisabled="!selectedSite"
      />
      <div class="style-settings">
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.fontStyle.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Font Style</span>
            </label>
            <div v-if="!form.fontStyle.collapsed" class="restore" @click="restore('fontStyle')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.fontStyle.collapsed" class="list">
            <div class="list-item">
              <div class="list-item-title">font</div>
              <div class="list-item-input-wrapper">
                <div class="website-wrapper">
                  <base-dropdown
                    class="website website-with-icon select"
                    item-label="family"
                    :searchable="true"
                    track-by="family"
                    v-model="form.fontStyle.font"
                    :options="fonts"
                  />
                  <icon-font-badge class="select-icon" width="18" height="18" />
                </div>
              </div>
            </div>
            <div class="list-item">
              <div class="list-item-title">weight</div>
              <div class="list-item-input-wrapper">
                <div class="website-wrapper">
                  <base-dropdown
                    class="website website-with-icon select"
                    item-label="title"
                    :searchable="true"
                    track-by="id"
                    v-model="form.fontStyle.weight"
                    :options="fontWeights"
                  />
                  <icon-font-weight-badge class="select-icon" width="18" height="18" />
                </div>
              </div>
            </div>
            <div class="list-item">
              <div class="list-item-title">text size</div>
              <div class="list-item-input-wrapper">
                <div class="website-wrapper">
                  <base-dropdown
                    class="website website-with-icon select"
                    item-label="title"
                    :searchable="true"
                    track-by="id"
                    v-model="form.fontStyle.textSize"
                    :options="fontSizes"
                  />
                  <icon-font-size-badge class="select-icon" width="18" height="18" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.textColor.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Text Color</span>
            </label>
            <div v-if="!form.textColor.collapsed" class="restore" @click="restore('textColor')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.textColor.collapsed" class="list">
            <div class="list-item">
              <BadgeColorPicker
                :value="form.textColor.operational"
                @hide-picker="hideColorPickers('textColor', 'operational')"
                title="operational"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.textColor.unavailable"
                @hide-picker="hideColorPickers('textColor', 'unavailable')"
                title="unavailable"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.textColor.investigation"
                @hide-picker="hideColorPickers('textColor', 'investigation')"
                title="investigation"
              />
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings}" v-if="form.style && form.style.id === 2">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.innerTextColor.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Inner Text Color</span>
            </label>
            <div v-if="!form.innerTextColor.collapsed" class="restore" @click="restore('innerTextColor')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.innerTextColor.collapsed" class="list">
            <div class="list-item">
              <BadgeColorPicker
                :value="form.innerTextColor.operationalInner"
                @hide-picker="hideColorPickers('innerTextColor', 'operationalInner')"
                title="operational - inner"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.innerTextColor.unavailableInner"
                @hide-picker="hideColorPickers('innerTextColor', 'unavailableInner')"
                title="unavailable - inner"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.innerTextColor.investigationInner"
                @hide-picker="hideColorPickers('innerTextColor', 'investigationInner')"
                title="investigation - inner"
              />
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.symbolColor.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Symbol Color</span>
            </label>
            <div v-if="!form.symbolColor.collapsed" class="restore" @click="restore('symbolColor')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.symbolColor.collapsed" class="list">
            <div class="list-item">
              <BadgeColorPicker
                :value="form.symbolColor.operational"
                @hide-picker="hideColorPickers('symbolColor', 'operational')"
                title="operational"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.symbolColor.unavailable"
                @hide-picker="hideColorPickers('symbolColor', 'unavailable')"
                title="unavailable"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.symbolColor.investigation"
                @hide-picker="hideColorPickers('symbolColor', 'investigation')"
                title="investigation"
              />
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings || form.style.id === 3}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.frameBackgroundColor.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Frame Background Color</span>
            </label>
            <div v-if="!form.frameBackgroundColor.collapsed" class="restore" @click="restore('frameBackgroundColor')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.frameBackgroundColor.collapsed" class="list">
            <div class="list-item">
              <BadgeColorPicker
                :value="form.frameBackgroundColor.operational"
                @hide-picker="hideColorPickers('frameBackgroundColor', 'operational')"
                title="operational"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.frameBackgroundColor.unavailable"
                @hide-picker="hideColorPickers('frameBackgroundColor', 'unavailable')"
                title="unavailable"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.frameBackgroundColor.investigation"
                @hide-picker="hideColorPickers('frameBackgroundColor', 'investigation')"
                title="investigation"
              />
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings || (form.style.id === 2 || form.style.id === 3)}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.frameBorderColor.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Frame Border Color</span>
            </label>
            <div v-if="!form.frameBorderColor.collapsed" class="restore" @click="restore('frameBorderColor')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.frameBorderColor.collapsed" class="list">
            <div class="list-item">
              <BadgeColorPicker
                :value="form.frameBorderColor.operational"
                @hide-picker="hideColorPickers('frameBorderColor', 'operational')"
                title="operational"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.frameBorderColor.unavailable"
                @hide-picker="hideColorPickers('frameBorderColor', 'unavailable')"
                title="unavailable"
              />
            </div>
            <div class="list-item">
              <BadgeColorPicker
                :value="form.frameBorderColor.investigation"
                @hide-picker="hideColorPickers('frameBorderColor', 'investigation')"
                title="investigation"
              />
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.customText.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Custom Text</span>
            </label>
            <div v-if="!form.customText.collapsed" class="restore" @click="restore('customText')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.customText.collapsed" class="list">
            <div class="list-item">
              <div class="list-item-title">operational</div>
              <div class="list-item-input-wrapper">
                <base-textbox
                  placeholder="Text (optional)"
                  v-model="form.customText.operational"
                  class="list-item-input-no-padding bordered wide m-0"
                  :bordered="false"
                />
                <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" rx="4" :fill="'#' + form.customText.operational"/>
                </svg> -->
              </div>
            </div>
            <div class="list-item">
              <div class="list-item-title">unavailable</div>
              <div class="list-item-input-wrapper">
                <base-textbox
                  placeholder="Text (optional)"
                  v-model="form.customText.unavailable"
                  class="list-item-input-no-padding bordered wide m-0"
                  :bordered="false"
                />
                <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" rx="4" :fill="'#' + form.customText.unavailable"/>
                </svg> -->
              </div>
            </div>
            <div class="list-item">
              <div class="list-item-title">investigation</div>
              <div class="list-item-input-wrapper">
                <base-textbox
                  placeholder="Text (optional)"
                  v-model="form.customText.investigation"
                  class="list-item-input-no-padding bordered wide m-0"
                  :bordered="false"
                />
                <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" rx="4" :fill="'#' + form.customText.investigation"/>
                </svg> -->
              </div>
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.customCss.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Custom CSS</span>
            </label>
            <div v-if="!form.customCss.collapsed" class="restore" @click="restore('customCss')">
              Restore Default
            </div>
          </div>
          <div style="display: none">
            <CustomCss></CustomCss>
          </div>
          <div v-if="!form.customCss.collapsed" class="list">
            <div class="list-item-full-width">
              <div>
                <div class="list-flex-start">
                  <div class="w-100">
                    <div class="list-item-title">custom CSS</div>
                    <div @click="!form.customCss.value && (form.customCss.value = ' ')">
                      <CustomCss v-model="form.customCss.value"></CustomCss>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" :class="{'style-settings-disabled': isDisabledSettings}">
          <div class="title-wrapper">
            <label class="d-flex align-items-center">
              <span class="switch">
                <input v-model="form.badge_url.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
              <span class="title switch-title">Badge URL</span>
            </label>
            <div v-if="!form.badge_url.collapsed" class="restore" @click="restore('badge_url')">
              Restore Default
            </div>
          </div>
          <div v-if="!form.badge_url.collapsed" class="list">
            <div class="list-item-full-width">
              <div class="list-item-title">link</div>
              <div class="list-item-input-wrapper">
                <base-textbox
                  placeholder="https://"
                  v-model="form.badge_url.value"
                  class="list-item-input-no-padding bordered wide m-0"
                  :bordered="false"
                />
              </div>
              <br>
              <div v-if="!form.badge_url.collapsed" >
                <div class="d-flex mt-3">
                  <base-checkbox label="Open in new tab"
                                 v-model="form.badge_url.open_in_new_tab"/>
                </div>
                <p class="checkbox-description">By clicking the link on the badge, the Badge URL will be opened in a new tab.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item" style="margin-left: 0; margin-top: 0" :class="{'style-settings-disabled': isDisabledSettings}">
          <div class="d-flex mt-3">
            <base-checkbox label="Show the uptime percentage as a whole number"
                           v-model="form.wholeUptimeNumber"/>
          </div>
          <p class="checkbox-description">This option will remove the decimal numbers from the uptime percentage.</p>
        </div>
      </div>
      <div id="custom-styles"></div>
    </form>
  </ValidationObserver>
</template>

<script>
import BadgeStyleSelect from '@/components/Badge/StyleSelect.vue'
import badgeApi from '@/api/badgeApi.js'
import badgeStyles from '@/entities/badge-styles.js'
import BadgeColorPicker from '@/components/Badge/BadgeColorPicker.vue'
import WebFont from 'webfontloader'
import CustomCss from '../Common/CustomCss'

export default {
  name: 'BadgeForm',
  components: {
    BadgeStyleSelect,
    BadgeColorPicker,
    CustomCss
  },
  data () {
    return {
      selectedSite: null,
      sites: [],
      badgeId: null,
      defaultSettings: JSON.parse(JSON.stringify(badgeStyles)),
      formSettings: JSON.parse(JSON.stringify(badgeStyles)),
      customCssTimeout: null,
      form: {
        wholeUptimeNumber: null,
        style: null,
        symbol: null,
        roundness: null,
        fontStyle: {
          collapsed: true,
          font: {
            id: 1,
            title: 'Inter',
            value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
          },
          weight: {
            id: 4,
            title: 'Medium',
            value: 500
          },
          textSize: {
            id: 7,
            title: 14
          }
        },
        textColor: {
          collapsed: true,
          type: 'color',
          operational: {
            color: '1B1823',
            isShowPicker: false
          },
          unavailable: {
            color: '1B1823',
            isShowPicker: false
          },
          investigation: {
            color: '1B1823',
            isShowPicker: false
          }
        },
        innerTextColor: {
          collapsed: true,
          type: 'color',
          operationalInner: {
            color: 'FFFFFF',
            isShowPicker: false
          },
          unavailableInner: {
            color: 'FFFFFF',
            isShowPicker: false
          },
          investigationInner: {
            color: 'FFFFFF',
            isShowPicker: false
          }
        },
        symbolColor: {
          collapsed: true,
          type: 'color',
          operational: {
            color: '27AE60',
            isShowPicker: false
          },
          unavailable: {
            color: 'A3A7A5',
            isShowPicker: false
          },
          investigation: {
            color: 'F2994A',
            isShowPicker: false
          }
        },
        frameBackgroundColor: {
          collapsed: true,
          type: 'color',
          operational: {
            color: 'E9F7EF',
            isShowPicker: false
          },
          unavailable: {
            color: 'F6F6F6',
            isShowPicker: false
          },
          investigation: {
            color: 'FEF8EF',
            isShowPicker: false
          }
        },
        frameBorderColor: {
          collapsed: true,
          type: 'color',
          operational: {
            color: 'C2E8D2',
            isShowPicker: false
          },
          unavailable: {
            color: 'E5E6E6',
            isShowPicker: false
          },
          investigation: {
            color: 'FCE5CE',
            isShowPicker: false
          }
        },
        customText: {
          collapsed: true,
          operational: 'All Systems Operational',
          unavailable: 'Server Unavailable',
          investigation: 'Exploring Issues'
        },
        customCss: {
          collapsed: false,
          value: ''
        },
        badge_url: {
          collapsed: false,
          value: '',
          open_in_new_tab: false
        }
      },
      styleItems: [
        {
          id: 1,
          title: 'style',
          items: [
            {
              id: 1,
              selected: false,
              html: '<div class="chip chip-1">Button</div>'
            },
            {
              id: 2,
              selected: false,
              html: `
                <div class="chip chip-2">
                  <div class="text-container">Status</div>
                  <div class="chip-percent">
                    <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                        <path d="M3.06811 0.740381C3.26105 0.409629 3.73895 0.409629 3.93189 0.740382L6.56137 5.24806C6.75581 5.58139 6.51538 6 6.12948 6H0.870518C0.484623 6 0.244187 5.58139 0.438629 5.24806L3.06811 0.740381Z" fill="white"/>
                      </g>
                    </svg>
                    <div>100%</div>
                  </div>
                </div>
              `
            },
            {
              id: 3,
              selected: false,
              html: '<div class="chip chip-3">Icon</div>'
            }
          ]
        },
        {
          id: 2,
          title: 'symbol',
          items: [
            {
              id: 1,
              selected: false,
              html: `
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.25168 1.14697C8.01369 -0.224651 9.9863 -0.224653 10.7483 1.14697L17.3493 13.0287C18.0899 14.3618 17.1259 16 15.601 16H2.39903C0.87406 16 -0.089876 14.3618 0.650714 13.0287L7.25168 1.14697Z" fill="#1B1823"/>
                </svg>
              `
            },
            {
              id: 2,
              selected: false,
              html: `
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8" fill="#1B1823"/>
                </svg>
              `
            },
            {
              id: 3,
              selected: false,
              html: `
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" rx="4" fill="#1B1823"/>
                </svg>
              `
            }
          ]
        },
        {
          id: 3,
          title: 'roundness',
          items: [
            {
              id: 1,
              selected: false,
              html: '<div class="round round-1">Square</div>'
            },
            {
              id: 2,
              selected: false,
              html: '<div class="round round-2">Rounded</div>'
            },
            {
              id: 3,
              selected: false,
              html: '<div class="round round-3">Round</div>'
            }
          ]
        }
      ],
      fonts: [],
      fontWeights: [
        {
          id: 1,
          title: 'Thin',
          value: 100
        },
        {
          id: 2,
          title: 'Light',
          value: 300
        },
        {
          id: 3,
          title: 'Regular',
          value: 400
        },
        {
          id: 4,
          title: 'Medium',
          value: 500
        },
        {
          id: 5,
          title: 'Semi Bold',
          value: 600
        },
        {
          id: 6,
          title: 'Bold',
          value: 700
        },
        {
          id: 7,
          title: 'Extra Bold',
          value: 800
        },
        {
          id: 8,
          title: 'Black',
          value: 900
        }
      ]
    }
  },
  async mounted () {
    await this.loadFonts()
    let data = await axios.get('/monitors/')
    this.sites = data.data.data.map(item => {
      item.title = item.name || item.url.split('://')[1]
      return item
    })
    if (this.$route.params.id) {
      this.selectedSite = this.sites.find(item => item.id === parseInt(this.$route.params.id))
    }
  },
  methods: {
    async loadFonts () {
      let fonts = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.VUE_APP_GOOGLE_FONTS_KEY}`)
      fonts = await fonts.json()
      this.fonts = fonts.items.map(item => {
        item.value = `${item.family}, ${item.category}`
        return item
      })
      this.form.fontStyle.font = this.fonts.find(item => item.family === 'Inter')
      this.defaultSettings.map(item => {
        item.fontStyle.font = this.form.fontStyle.font
      })
      this.formSettings.map(item => {
        item.fontStyle.font = this.form.fontStyle.font
      })
    },
    async getBadge () {
      try {
        let badge = await badgeApi.get(this.selectedSite.id)
        for (let index = 0; index < 3; index++) {
          this.formSettings[index].badge_url = JSON.parse(badge.badge_url)
        }
        // let badge = badges.find(item => item.monitor_id === this.selectedSite.id)
        this.selectStyle('style', 1, JSON.parse(badge.style).id)
        this.selectStyle('symbol', 2, JSON.parse(badge.symbol).id)
        this.form.roundness = null
        this.styleItems[2].items = this.styleItems[2].items.map(item => {
          return {
            ...item,
            selected: false
          }
        })
        if (JSON.parse(badge.roundness)) {
          this.selectStyle('roundness', 3, JSON.parse(badge.roundness).id)
        }
        this.badgeId = badge.id
        setTimeout(() => {
          this.form.fontStyle = JSON.parse(badge.font_style)
          this.form.textColor = JSON.parse(badge.text_color)
          if (badge.inner_text_color && JSON.parse(badge.inner_text_color)) {
            this.form.innerTextColor = JSON.parse(badge.inner_text_color)
          } else {
            this.form.innerTextColor = { ...this.defaultSettings[this.form.style.id - 1].innerTextColor }
          }
          this.form.symbolColor = JSON.parse(badge.symbol_color)
          this.form.frameBackgroundColor = JSON.parse(badge.frame_background_color)
          this.form.frameBorderColor = JSON.parse(badge.frame_border_color)
          this.form.customText = JSON.parse(badge.custom_text)
          if (badge.badge_url && JSON.parse(badge.badge_url)) {
            this.form.badge_url = JSON.parse(badge.badge_url)
          } else {
            this.form.badge_url = { ...this.defaultSettings[this.form.style.id - 1].badge_url }
          }
          if (badge.custom_css && JSON.parse(badge.custom_css)) {
            this.form.customCss = JSON.parse(badge.custom_css)
            if (typeof this.form.customCss !== 'object') {
              this.form.customCss = {
                ...this.defaultSettings[this.form.style.id - 1].customCss,
                value: this.form.customCss
              }
            } else {
              if (!this.form.customCss.value) {
                this.form.customCss.value = ''
              }
            }
            this.form.wholeUptimeNumber = JSON.parse(badge.whole_uptime_number)
            this.setBadgeCustomStyles()
          } else {
            this.form.customCss = { ...this.defaultSettings[this.form.style.id - 1].customCss }
          }
          this.setDefaultStyles()
        }, 1)
      } catch (error) {
        this.form.style = null
        this.form.symbol = null
        this.form.roundness = null
        for (let key in this.form) {
          if (typeof this.defaultSettings[0][key] === 'object') {
            for (let itemKey in this.form[key]) {
              if (typeof this.defaultSettings[0][key][itemKey] === 'object') {
                this.form[key][itemKey] = { ...this.defaultSettings[0][key][itemKey] }
              } else {
                this.form[key][itemKey] = this.defaultSettings[0][key][itemKey]
              }
            }
          }
        }
        this.setDefaultStyles()
        this.badgeId = null
        this.styleItems.forEach((item, index) => {
          this.styleItems[index].items = this.styleItems[index].items.map(item => {
            return {
              ...item,
              selected: false
            }
          })
        })
      }
    },
    setDefaultStyles () {
      for (let key in this.form) {
        if (this.form.style) {
          if (this.defaultSettings[this.form.style.id - 1][key]) {
            this.form[key].default = this.defaultSettings[this.form.style.id - 1][key]
          }
        }
      }
    },
    selectStyle (formKey, styleId, id) {
      let styleIndex = this.styleItems.findIndex(item => item.id === styleId)
      if (!styleIndex && styleIndex !== 0) return
      let itemIndex = this.styleItems[styleIndex].items.findIndex(item => item.id === id)
      if (!itemIndex && itemIndex !== 0) return
      this.form[formKey] = this.styleItems[styleIndex].items[itemIndex]
      this.styleItems[styleIndex].items = this.styleItems[styleIndex].items.map(item => {
        item.selected = false
        return item
      })
      this.styleItems[styleIndex].items[itemIndex].selected = true
    },
    restore (key) {
      if (this.form.style) {
        if (this.form[key].type === 'color') {
          for (let itemKey in this.form[key]) {
            if (itemKey !== 'collapsed') {
              if (typeof this.defaultSettings[this.form.style.id - 1][key][itemKey] === 'object') {
                this.form[key][itemKey] = { ...this.defaultSettings[this.form.style.id - 1][key][itemKey] }
              } else {
                this.form[key][itemKey] = this.defaultSettings[this.form.style.id - 1][key][itemKey]
              }
            }
          }
        } else {
          this.form[key] = {
            ...this.defaultSettings[this.form.style.id - 1][key],
            collapsed: this.form[key].collapsed
          }
          this.formSettings[this.form.style.id - 1][key] = this.defaultSettings[this.form.style.id - 1][key]
        }
      }
    },
    hideColorPickers (selectedFormKey, selectedKey) {
      let isShowPicker = null
      if (selectedFormKey) {
        isShowPicker = !this.form[selectedFormKey][selectedKey].isShowPicker
      }
      for (let formKey in this.form) {
        for (let key in this.form[formKey]) {
          if (this.form[formKey].type === 'color' && typeof this.form[formKey][key] === 'object') {
            this.form[formKey][key].isShowPicker = false
          }
        }
      }
      if (selectedFormKey) {
        this.form[selectedFormKey][selectedKey].isShowPicker = isShowPicker
      }
    },
    getPickerValue (value) {
      return '#' + value.substring(0, value.length === 8 ? value.length - 2 : value.length)
    },
    roughScale (x, base) {
      const parsed = parseInt(x, base)
      if (isNaN(parsed)) return 0
      return parsed * 100
    },
    percentage (partialValue, totalValue) {
      return (100 * partialValue) / totalValue
    },
    getOpacity (value) {
      if (value && value.length === 8) {
        let opacity16 = this.roughScale(value.slice(-2), 16)
        return parseInt(this.percentage(opacity16, 25500)) + '%'
      } else {
        return '100%'
      }
    },
    setBadgeCustomStyles () {
      let customStyles = document.getElementById('custom-styles')
      if (!this.form.customCss.collapsed) {
        customStyles.innerHTML = `
          <style>${this.form.customCss.value}</style>
        `
      } else {
        customStyles.innerHTML = ''
      }
    }
  },
  computed: {
    fontSizes () {
      let result = []
      for (let index = 8; index <= 50; index++) {
        result.push({
          id: index - 7,
          title: index
        })
      }
      return result
    },
    isDisabledSettings () {
      return (!this.form.style || !this.form.symbol || !this.form.roundness) && !(this.form.style && this.form.style.id === 3 && this.form.symbol)
    },
    styleItemsList () {
      let result = []
      if (this.selectedSite) {
        result.push(this.styleItems[0])
      }
      if (this.selectedSite && this.form.style) {
        result.push(this.styleItems[1])
      }
      if (this.selectedSite && this.form.style && this.form.symbol) {
        result.push(this.styleItems[2])
      }
      return result
    }
  },
  watch: {
    'form.style': function () {
      if (this.form.style) {
        for (let key in this.formSettings[this.form.style.id - 1]) {
          this.form[key] = this.formSettings[this.form.style.id - 1][key]
        }
        this.setDefaultStyles()
      }
    },
    'form.fontStyle.font': function () {
      WebFont.load({
        google: {
          families: [this.form.fontStyle.font.family]
        }
      })
    },
    'form.customCss.value': function () {
      if (this.customCssTimeout) {
        clearTimeout(this.customCssTimeout)
      }
      this.customCssTimeout = setTimeout(() => {
        this.setBadgeCustomStyles()
      }, 2000)
    },
    'form.customCss.collapsed': function () {
      this.setBadgeCustomStyles()
    },
    form: {
      handler: function () {
        if (this.form.style) {
          if (this.form.style.id === 2) {
            this.form.frameBorderColor.collapsed = true
          }
          if (this.form.style.id === 3) {
            this.form.frameBackgroundColor.collapsed = true
            this.form.frameBorderColor.collapsed = true
          }
        }
        this.$emit('change', this.form)
      },
      deep: true,
      immediate: true
    },
    selectedSite: {
      handler: function () {
        if (this.selectedSite) {
          this.$emit('set-site-id', this.selectedSite.id)
          this.getBadge()
        }
      },
      deep: true,
      immediate: true
    },
    badgeId () {
      this.$emit('set-badge-id', this.badgeId)
    }
  }
}
</script>

<style lang="scss" scoped>
.BadgePageForm {
  .header {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .website {
    width: 100%;
    &-main {
      @extend .website;
      z-index: 9;
    }
    ::v-deep {
      .multiselect__content {
        .multiselect__option--selected {
          color: #6551E0 !important;
        }
        .multiselect__option {
          padding-left: 30px;
          &--selected {
            padding-left: 12px;
            color: #6551E0 !important;
            display: flex;
            align-items: center;
            &:before {
              width: 8px;
              height: 6px;
              display: flex !important;
              align-items: center;
              content:url('../../assets/images/badge-check.svg');
              margin-right: 10px;
            }
          }
          &--highlight {
            color: white !important;
            &:before {
              content:url('../../assets/images/badge-check-white.svg') !important;
              display: none;
            }
          }
        }
      }
    }
    &-with-icon {
      ::v-deep {
        .multiselect__tags {
          padding-left: 44px !important;
        }
      }
    }
    &-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      ::v-deep {
        .base-dropdown {
          margin-bottom: 0;
        }
      }
      .select-icon {
        position: absolute;
        left: 14px;
        width: 16px;
        z-index: 50;
      }
    }
  }
  ::v-deep {
    .chip {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      &-1 {
        width: 70%;
        position: relative;
        border: 1px solid rgba(39, 174, 96, 0.2);
        background: #E9F7EF;

        background: rgba(137, 136, 142,.21);
        border: 1px solid rgba(137, 136, 142,.19);

        box-sizing: border-box;
        border-radius: 100px;
        padding: 7px 14px 7px 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
          content: "";
          position: absolute;
          left: 14px;
          width: 8px;
          height: 8px;
          background: #89888e;
          border-radius: 100%;
        }
      }
      &-2 {
        display: flex;
        align-items: center;
        .text-container {
          margin-right: 6px;
        }
        .chip-percent {
          padding: 0 6px;
          color: white;
          background: #89888e;
          border-radius: 4px;
          font-weight: 500;
          display: flex;
          align-items: center;
          height: 24px;
          svg {
            margin-right: 6px;
          }
        }
      }
      &-3 {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 18px;
        &:after {
          content: "";
          position: absolute;
          right: 0;
          width: 12px;
          height: 12px;
          background: #89888e;
          border-radius: 3px;
        }
      }
    }

    .chip-text-wrapper {
      margin-right: 6px;
    }

    .item-selected {
      .chip-1 {
        border: 1px solid rgba(39, 174, 96, 0.2);
        background: #E9F7EF;

        &:after {
          background: #27AE60;
        }
      }
      .chip-2 .chip-percent {
        background: #27AE60;

        svg path {
          fill: white;
        }
      }

      .chip-3:after {
        background: #27AE60;
      }
      .round {
        border: 1px solid rgba(39, 174, 96, 0.2);
        background: #E9F7EF;
      }
      .item-content svg {
        path, rect, circle {
          fill: #27ae60;
        }
      }
    }

    .round {
      background: rgba(27, 24, 35, 0.05);
      border: 1px solid rgba(27, 24, 35, 0.1);
      box-sizing: border-box;
      height: 24px;
      width: 100%;
      margin: -4px 0;

      height: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 38px;
      text-align: center;

      &-1 {
        border-radius: 0;
      }
      &-2 {
        border-radius: 4px;
      }
      &-3 {
        border-radius: 100px;
      }
    }

  }
  .style-settings {
    @media (max-width: 500px) {
      margin-top: 10px;
    }
    .item {
      margin: 48px -10px 0;
      @media (max-width: 500px) {
        margin: 0 -10px;
        padding: 10px 0 10px;
      }
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.022em;
        color: #1B1823;
        &-wrapper {
          margin: 0 10px 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .restore {
            cursor: pointer;
            color: #6551E0;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.005em;
          }
        }
      }
      .list:first-child {
        margin-top: 0 !important;
      }
      .list {
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media (max-width: 500px) {
          flex-direction: column;
          margin-top: 0;
        }
        &-item {
          width: 33.33%;
          margin: 0 10px !important;
          position: relative;
          @media (max-width: 500px) {
            width: 100%;
            margin: 0 0 15px 0 !important;
          }
          &-title {
            font-weight: 600;
            font-size: 11px;
            letter-spacing: 0.05em;
            color: rgba(27, 24, 35, .5);
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          .color-picker {
            position: absolute;
            z-index: 30;
            top: calc(100% + 5px);
          }
          ::v-deep {
            .base-text-box {
              @media (max-width: 500px) {
                width: 100%;
              }
            }
          }
          &-opacity {
            position: absolute;
            right: 14px;
            margin-top: 2px;
          }
          &-input {
            @media (max-width: 500px) {
              width: 100%;
            }
            &-no-padding {
              ::v-deep {
                .input {
                  padding-left: 12px;
                }
              }
            }
            &-wrapper {
              position: relative;
              display: flex;
              align-items: center;
              .select {
                ::v-deep {
                  .multiselect__single {
                    max-width: calc(100% - 10px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
              svg {
                left: 14px;
                position: absolute;
              }
            }
            ::v-deep {
              .input {
                padding-left: 44px;
              }
            }
          }
        }
      }
      .checkbox-description {
        font-size: 12px;
        padding-left: 30px;
        margin-top: -17px;
        margin-bottom: 20px;
        opacity: 0.6;
        line-height: 17px;
      }
    }
    &-disabled {
      // position: relative;
      // opacity: .5;
      display: none;
      // &:after {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   content: "";
      // }
    }
  }
  .switch {
    margin-right: 15px;
    display: flex;
    align-items: center;
    position: relative;
    width: 26px;
    height: 14px;
    input {
      display: none;
    }
    &-title {
      cursor: pointer;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    height: 14px;
    transition: 0.15s;
    border: 0 !important;
    border-radius: 100px;
    background-color: map-get($colors, purple-1);
  }
  .slider:before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    left: 2px;
    background-color: #fff;
    transition: 0.15s;
    transform: translateX(12px);
  }

  .colorpicker {
    border-radius: 3.5px;
    stroke: rgba(0,0,0,.1);
    stroke-width: 1.5px;
    cursor: pointer;
  }

  //input:checked + .slider {
  //  background-color: #101010;
  //}
  //
  //input:focus + .slider {
  //  box-shadow: 0 0 1px #101010;
  //}

  input:checked + .slider {
    background: #88888e;
  }

  input:checked + .slider:before {
    transform: translateX(0);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .cm-s-base16-light.CodeMirror {
    background: #fff !important;
  }
}
</style>
