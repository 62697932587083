<template>
  <codemirror
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('input', $event)"
    class="custom-css"
    ref="myCm"
    :options="cmOptions"
  ></codemirror>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/css/css.js'
import 'codemirror/theme/base16-light.css'
import 'codemirror/addon/selection/active-line.js'

export default {
  name: 'CustomCss',
  components: { codemirror },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      cmOptions: {
        tabSize: 2,
        mode: 'text/css',
        // theme: 'base16-light',
        lineNumbers: false,
        line: true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-css {
  border-radius: 6px;
  //box-shadow: 0 2px 4px rgb(25 34 29 / 5%);
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
  border: 1px solid #E9EBEC;

  padding: 6px;
  background: #fff;

  .cm-s-base16-light.CodeMirror {
    background: #fff !important;
  }

  .cm-s-base16-light span.cm-error {
    background: transparent !important;
  }
}
</style>
