<template>
  <div class="BadgePage">
    <div class="top">
      <div class="page-header">
        Badge Pages
      </div>
      <!-- <div class="right" v-if="user.role !== role.Viewer">
        <base-button
          color="primary"
          ref="newStatusPageButton"
          class="addNew"
          @click="saveBadge"
          :disabled="isDisabledSettings"
        >
          Save Badge
        </base-button>
        <base-button color="primary" class="addNew preview-button" @click="isOpenPreview = true">
          Preview
        </base-button>
      </div> -->
    </div>
    <div class="content">
      <div class="form">
        <BadgeForm
          @change="form = $event"
          @set-site-id="siteId = $event"
          @set-badge-id="badgeId = $event"
          ref="BadgeForm"
        ></BadgeForm>

        <div class="toolbar mt-4" v-if="!isDisabledSettings && isSubscribed">
          <base-button
            ref="newStatusPageButton"
            class="addNew ml-0 success"
            @click="saveBadge"
            :disabled="isDisabledSettings"
          >
            Save Badge
          </base-button>
          <base-button
            type="button"
            class="is-outlined"
            ref="cancel"
            color="light"
            @click="$router.push({ name: 'BadgesPage' })"
          >
            Cancel
          </base-button>
        </div>
      </div>
      <div class="preview" :class="{'preview-open': isOpenPreview}" @click="isOpenPreview = false">
        <div class="preview-content" @click.stop>
          <BadgePreview
            v-if="!isDisabledSettings"
            :form="form"
            :result="form.style"
            :badgeId="badgeId"
            :siteId="siteId"
            @close="isOpenPreview = false"
          ></BadgePreview>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Role } from '@/_helpers/role.js'
import BadgeForm from '@/components/Forms/BadgePageForm.vue'
import BadgePreview from '@/components/Badge/Preview.vue'
import badgeApi from '@/api/badgeApi.js'
import tippy from 'tippy.js'

export default {
  metaInfo: {
    title: 'Badge Pages'
  },
  components: { BadgeForm, BadgePreview },
  data () {
    return {
      role: Role,
      form: {},
      siteId: null,
      badgeId: null,
      isOpenPreview: false
    }
  },
  computed: {
    ...mapState('authentication', [
      'user'
    ]),
    isDisabledSettings () {
      return (!this.form.style || !this.form.symbol || !this.form.roundness) && !(this.form.style && this.form.style.id === 3 && this.form.symbol)
    },
    isSubscribed () {
      return this.user.teamowner_plan && this.user.teamowner_plan.name !== 'Free'
    }
  },
  methods: {
    async saveBadge () {
      try {
        let data = {}
        for (let key in this.form) {
          data[key] = this.form[key]
        }
        await badgeApi.save(data, this.siteId)
        this.showSuccess()
        await this.$refs.BadgeForm.getBadge()
      } catch (error) {
        this.showFailed()
      }
    },
    showSuccess () {
      this.updateButtonTippy = tippy(this.$refs.newStatusPageButton.$el, {
        content: 'Badge has been saved',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })

      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    showFailed () {
      this.updateButtonTippy = tippy(this.$refs.newStatusPageButton.$el, {
        content: 'There was an error',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })

      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    }
  },
  async mounted () {
    let container = document.getElementsByClassName('content')[0]
    container.addEventListener('click', () => {
      if (this.$refs.BadgeForm) {
        this.$refs.BadgeForm.hideColorPickers()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.BadgePage {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  .page-header {
    margin-bottom: 0;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .right {
    display: flex;
  }
  .addNew {
    margin-left: 10px;
    height: 40px;
    @media (max-width: 500px) {
      margin-left: 0;
      margin-right: 10px;
    }
    ::v-deep {
      svg {
        margin-right: 0 !important;
      }
    }
  }
  ::v-deep {
    .preview-button {
      display: none;
      @media (max-width: 991px) {
        display: block;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .form {
      width: 670px;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .preview {
      width: 300px;
      margin-left: 20px;
      @media (max-width: 991px) {
        top: 0;
        left: 0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .5);
        z-index: 200;
        justify-content: center;
        align-items: center;
        display: none;
      }
      &-open {
        @media (max-width: 991px) {
          display: flex;
        }
      }
      &-content {
        width: 100%;
        position: sticky;
        top: 80px;
        @media (max-width: 991px) {
          border-radius: 20px;
          width: 500px;
          padding: 24px;
          background-color: map-get($colors, background);
        }
        @media (max-width: 600px) {
          border-radius: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
        }
      }
    }
  }

  .toolbar {
    z-index: 11;
    position: sticky;
    bottom:0;
    background: rgba(245, 248, 249, 0.9);
    padding: 15px 0;
    backdrop-filter: blur(3px);
  }
}
</style>
